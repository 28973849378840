import type {
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class emailParamToLowerCaseInterceptor implements HttpInterceptor {
    intercept(req: HttpRequest<any>, next: HttpHandler) {
        let modifiedReq = req;

        // Verify and transform queryParams
        const params = req.params;
        const emailFromQueryParam = params.get('email');
        if (emailFromQueryParam) {
            modifiedReq = req.clone({
                params: params.set('email', emailFromQueryParam.toLowerCase()),
            });
        }

        // Verify and transform body
        const body = req.body;
        if (body && body.email) {
            const modifiedBody = { ...body, email: body.email.toLowerCase() };
            modifiedReq = req.clone({ body: modifiedBody });
        }

        //Verify and transform formData
        if (body instanceof FormData && body.has('email')) {
            const modifiedFormData = new FormData();
            body.forEach((value, key) => {
                if (key === 'email') {
                    modifiedFormData.append(
                        key,
                        value.toString().toLowerCase()
                    );
                } else {
                    modifiedFormData.append(key, value);
                }
            });
            modifiedReq = req.clone({ body: modifiedFormData });
        }

        return next.handle(modifiedReq);
    }
}
