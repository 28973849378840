import { NgModule, Sanitizer } from '@angular/core';
import { httpInterceptorProviders } from '@core/_interceptors';
import { SharedCoreModule } from '@core/shared/shared-core.module';
import {
    HttpCacheInterceptorModule,
    useHttpCacheLocalStorage,
} from '@ngneat/cashew';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { DialogService } from 'primeng/dynamicdialog';
import { ToastModule } from 'primeng/toast';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppLayoutModule } from './layout/app.layout.module';

//plugins
import { FullCalendarModule } from '@fullcalendar/angular';
import { DOMPURIFY_CONFIG, NgDompurifySanitizer } from '@taiga-ui/dompurify';
import { MarkdownModule } from 'ngx-markdown';

@NgModule({
    imports: [
        AppLayoutModule,
        AppRoutingModule,
        ConfirmDialogModule,
        ConfirmPopupModule,
        HttpCacheInterceptorModule.forRoot({
            ttl: 86400000, // 1 day of Cache Globally
        }),
        SharedCoreModule,
        ToastModule,
        FullCalendarModule,
        MarkdownModule.forRoot(),
    ],
    declarations: [AppComponent],
    providers: [
        ConfirmationService,
        httpInterceptorProviders,
        MessageService,
        useHttpCacheLocalStorage,
        DialogService,
        {
            provide: Sanitizer,
            useClass: NgDompurifySanitizer,
        },
        {
            // CUSTOM CONFIGURATION SANITIZER TO GRANT OR BAN SOME ATTRIBUTES in the DOM
            provide: DOMPURIFY_CONFIG,
            useValue: { FORBID_ATTR: ['id'], FORBID_TAGS: ['script', 'style'] },
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
