/* "Barrel" of Http Interceptors */
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { emailParamToLowerCaseInterceptor } from '@core/_interceptors/email-param-to-lower-case.interceptor';
import { ErrorInterceptor } from './error.interceptor';
import { JwtInterceptor } from './jwt.interceptor';

/** Http interceptor providers in outside-in order */
export const httpInterceptorProviders = [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    {
        provide: HTTP_INTERCEPTORS,
        useClass: emailParamToLowerCaseInterceptor,
        multi: true,
    },
];
